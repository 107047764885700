// Entry point for the build script in your package.json
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "./channels"
import * as siret from "siret"

Rails.start()
ActiveStorage.start()

import './controllers'

// LEAFLET
import L from 'leaflet';
import * as Gp from 'geoportal-extensions-leaflet';
window.L = L
window.Gp = Gp
window.siret = siret
window.Rails = Rails

import "leaflet/dist/leaflet.css"
// https://github.com/PaulLeCam/react-leaflet/issues/255
import marker from 'leaflet/dist/images/marker-icon.png';
import marker2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: marker2x,
    iconUrl: marker,
    shadowUrl: markerShadow
});
